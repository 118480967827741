body {
  background: #192437;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.content {
  width: 60%;
  margin-top: 60px; /* Adjust this to account for the navbar height */
}

@media (max-width: 768px) {
  .content {
    width: 90%; /* More flexible width for tablets */
  }
}

@media (max-width: 600px) {
  .content {
    width: 100%;
    margin-top: 60px; /* Adjust this to account for the navbar height */
  }

  .movie-card {
    width: 100%;
  }

  .movie-player iframe {
    height: 250px;
  }
}

.pagination-container {
  display: flex;
  justify-content: center; /* Centers the pagination */
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #e9ecef;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  background-color: #d78e2f;
}

.active .page-link {
  background-color: #d78e2f;
  color: white;
  border: 1px solid #d78e2f;
}

.random-button {
  background-color: #f39c12; /* Orange background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for the button */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  display: block;
}

.random-button:hover {
  background-color: #e67e22; /* Darker orange on hover */
}

.random-button:focus {
  outline: none; /* Remove the default focus outline */
}

.random-button:active {
  background-color: #d35400; /* Even darker orange when clicked */
}

/* Dropdown container */
.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
}

/* Dropdown label */
.filter-container label {
  font-size: 16px;
  color: #f5f5f5;
  margin-right: 10px;
}

/* Dropdown select */
.filter-container select {
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #333;
  color: #fff;
}

/* Mobile-friendly adjustments */
@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .filter-container select {
    width: 100%;
    padding: 10px;
    font-size: 18px;
  }

  .filter-container label {
    margin-right: 0;
    font-size: 18px;
  }
}

/* Buttons section */
.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .buttons {
    flex-direction: column;
    gap: 10px;
  }
}

/* MMS button styling */
.mms-btn {
  position: relative;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2); /* Light white overlay */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
}

.mms-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://www.indianseries.site/mms.jpg'); /* Replace with the path to your image */
  background-size: cover;
  background-position: center;
  opacity: 0.3; /* Adjust this value for transparency */
  z-index: -1; /* Place it behind the text */
}

.mms-btn:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}
