.movie-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
}
.movie-list-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
}
.movie-list-1 > * {
  flex: 1 1 calc(100% - 20px);
}

.notification-card {
  background-color: #f5f5f5;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notification-card h3 {
  margin: 0;
  font-size: 1.5em;
  color: #333;
}

.notification-card p {
  margin: 10px 0;
  color: #666;
}

.join-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border-radius: 4px;
  text-decoration: none;
}

.join-button:hover {
  background-color: #0056b3;
}
