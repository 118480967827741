.movie-player {
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  color: #ffffffa3;

}

.movie-player h2 {
  margin-bottom: 10px;
  text-align: center;
}

.movie-player iframe {
  width: 100%;
  height: 300px;
  border: none;
}

.movie-player-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 2px; */
  margin: 10px 0;
}

.movie-button {
  background: #d78e2f;
  border: none;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  flex: 1; /* Ensures buttons take up equal space */
  max-width: 48%; /* Prevents buttons from becoming too wide */
}
.movie-button-download {
  background: #d78e2f;
  border: none;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  flex: 1; /* Ensures buttons take up equal space */
  max-width: 100%; /* Prevents buttons from becoming too wide */
}

.movie-button:hover {
  background: #ff9100e2;
}

.recommended-videos-title {
  margin-top: 20px;
  font-size: 18px;
  text-align: left;
  padding-left: 10px;
}

.movie-tags {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.tag-button {
  background: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.3s;
}

.tag-button:hover {
  background: #555;
}


.click-instructions {
  /* display: block; */
  margin-top: 8px;
  font-size: 14px;
  color: #cabebe;
}
