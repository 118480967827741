.navbar {
  width: 100%;
  background: #192437;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.398);
  z-index: 1000;
  box-shadow: 0 2px 5px #0075ff61;
  box-sizing: border-box;
}

.logo{
  font-family: Arial, sans-serif;
  font-size: 20px;
}
.logo .ullu {
  color: #fff;
}
.logo .hub {
  background-color: #ff9000;
  border-radius: 4px;
  color: #000;
  margin-left: 2px;
  padding: 0 4px;
}

.navbar-brand {
  text-decoration: none;
}

.navbar-links {
  display: flex;
  gap: 5px;
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
  padding: 5px 10px;
  white-space: nowrap;
}

.navbar-link:hover {
  color: #f90;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px;
  transition: all 0.3s ease;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .navbar-links {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #192437;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .navbar-links.active {
    height: 150px; /* Adjust based on number of links */
  }

  .navbar-link {
    padding: 10px;
    font-size: 18px;
  }

  .hamburger {
    display: flex;
  }
}
